import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import qs from 'query-string'
import { Helmet } from "react-helmet"
import AdSense from 'react-adsense'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

import moment from 'moment'
import 'moment/locale/ko'
import { reverse } from 'lodash'

// 시간대별로 투자, 익절 손비 각각
// 내가 만드는 동물
// 번역기 또는 읽어주는 프로그램 통해서 나레이션 + 타이포 컴포넌트로 마치 방송하듯 24시간 방송국 만들기
// 질문이 들어오면 답변을 해주는 프로그램을 구성하기
// 플레이어 페이지를 애니멀 트레이더 랭킹

const comma = (x) => {
  if (!x || x < 1) { return x }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Search = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 1.5rem;
  & > input {
    display: block; box-sizing: border-box; width: 100%;
    border-radius: 0.35rem; outline: none; border: 0;
    padding: 0.5rem 0.75rem;
   }
`

const Grid = styled.div`
  display: flex; box-sizing: border-box;
`

Grid.Aside = styled.aside`
  min-width: 31%; max-width: 31%; margin: 0.5rem 1%; box-sizing: border-box;
  @media screen and (max-width: 680px) {
    position: fixed; bottom: -90%; left: 0; transition: all 0.3s; z-index: 999;
    min-width: 100%; max-width: 100%;
    min-height: 100%; max-height: 100%;
    background: #0b0c11; margin: 0;  overflow: hidden;
    &.active { bottom: 0; overflow-y: auto; }
  }
`

Grid.Body = styled.section`
  flex: 1 1 100%; min-width: 0; max-width: 100%; margin: 0.5rem 1%; box-sizing: border-box; 
  z-index: 1;
  @media screen and (max-width: 680px) { min-width: 0; max-width: 100%; }
`

const AdBox = styled.div`
  display: flex; margin-bottom: 1.5rem;
  & > div {
    flex: 1 1 100%; box-sizing: border-box;
    display: flex; align-items: center; justify-content: center;
    background: #0b0c11; border-radius: 0.35rem;
    padding: 1rem; margin: 0.35rem;
    & > div { min-width: 100%; width: 100%; height: 200px; box-sizing: border-box; }
    @media screen and (max-width: 680px) {
      padding: 0; margin: 0; border-radius: 0;
      & > div { height: auto; }
    }
  }
`

const Nav = styled.nav`
  position: relative; margin: 0.5rem auto;
  display: flex; justify-content: flex-start;
  flex-wrap: wrap; box-sizing: border-box;
  line-height: 1em;
  & > a {
    flex: 1 1 100%; position: relative; transition: all 0.3s;
    max-width: 31.1%; min-width: 31.1%;
    box-sizing: border-box; font-weight: 900;
    display: flex; justify-content: space-between; transition: all 0.3s;
    padding: 0.75rem; margin: 0.35rem 1%; box-sizing: border-box;
    font-size: 0.75em; background: #0b0c11; border-radius: 0.35rem;
    & .icon { font-weight: 100; font-size: 1.8em; }
    & small { color: yellow }
    &.dead {
      opacity: 0.2;
    }
    &:hover {
      background: #07080f;
      opacity: 0.9;
      & .text { color: yellow; }
    }
    & > .memo {
      position: absolute; left: 0.35rem; bottom: -0.35rem;
      border-radius: 0.35rem; font-weight: 100;
      font-size: 0.7em; color: black;  background: white;
      line-height: 1rem; padding: 0rem 0.25rem;
    }
    &.active {
      background: yellow;  opacity: 1 !important;
      & .text { color: #0b0c11; }
      & small { color: #666; }
    }
    @media screen and (max-width: 680px) {
      max-width: 48%; min-width: 48%;
    }
  }
`

Nav.Header = styled.header`
  padding: 0.55rem 0.75rem;
  display: none; align-items: center; justify-content: space-between;
  @media screen and (max-width: 680px) { display: flex; }
  & > div.icon {
    font-size: 1.5em; line-height: 1.8em; margin-right: 0.5rem; cursor: pointer;
    &:hover { color: yellow }
  }
  & > div.name { font-weight: 700; font-size: 1.2em; line-height: 1.8em; }
`

const FloxBox = styled.div`
  display: flex; max-width: 100%; box-sizing: border-box;
  overflow-y: auto;
  & > div.item {
    min-width: 195px;
    background: #0b0c11; border-radius: 0.35rem;
    border-bottom: 3px solid white;
    margin: 0.5rem 0.35rem;
    padding: 1rem; box-sizing: border-box;
    & > div.timestamp {
      font-size: 1.1em; color: #eee; padding-bottom: 0.5rem;
      & > strong { color: white; font-weight: 900; }
    }
    & > div.header { font-size: 0.8em; color: #eee; padding-bottom: 0.5rem; }
    & > div.icons {
      padding: 0.25rem 0; margin-bottom: 0.5rem;
      display: flex; flex-wrap: wrap;
      & > a {
        display: flex; margin: 0.25rem; align-items: center; justify-content: center;
        width: 32px; height: 32px;
        max-width: 32px; max-height: 32px;
        min-width: 32px; min-height: 32px;
        border-radius: 50%; background: white;
        &.dead { opacity: 0.5; }
        & > img {
          width: 20px; height: 20px; border-radius: 50%;
        }
      }
    }
  }
` 

const Flow = ({ completedActivateds = [], completedDeactivateds = [] }) => {
  
  const mts = [...new Set([
    ...completedActivateds.map(obj => moment(obj.enteranceAt).format('YYYY.MM.DD HH:mm')),
    ...completedDeactivateds.map(obj => moment(obj.enteranceAt).format('YYYY.MM.DD HH:mm'))
  ])].sort((a, b) => a < b ? 1 : -1)
  
  return (
    <FloxBox>
      {mts.map((mt, objIdx) => {
        const [curDay, curTime] = mt.split(' ')

        const enteranceItems = completedActivateds.filter(obj => moment(obj.enteranceAt).format('YYYY.MM.DD HH:mm') === mt)
        const exitItems = completedDeactivateds.filter(obj => moment(obj.enteranceAt).format('YYYY.MM.DD HH:mm') === mt)

        return (
          <div className="item" key={objIdx}>
            <div className="timestamp"><strong>{curTime}</strong> <small style={{ marginLeft: '0.15rem' }}>{curDay}</small></div>
            {exitItems.length ? (
              <>
                <div className="header">마감</div>
                <div className="icons">
                  {exitItems.map((obj, objIdx) => {
                    return (
                      <a
                        key={objIdx}
                        href={`/UPBIT:${obj.coinName}KRW`}
                        target="_blank"
                        className={obj.currentRate >= 0 ? `live` : `dead`}
                      >
                        <img
                          src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`}
                          title={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 마감가격 ${comma(obj.exitPrice)}원)`}
                          alt={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 마감가격 ${comma(obj.exitPrice)}원)`}
                        />
                      </a>
                    )
                  })}
                </div>
              </>
            ) : null}
            {enteranceItems.length ? (
              <>
                <div className="header">체결</div>
                <div className="icons">
                  {enteranceItems.map((obj, objIdx) => {
                      return (
                        <a
                          key={objIdx}
                          href={`/UPBIT:${obj.coinName}KRW`}
                          target="_blank"
                          className={obj.currentRate >= 0 ? `live` : `dead`}
                        >
                          <img
                            src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`}
                            title={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 현재시세 ${comma(obj.currentCoin.price)}원)`}
                            alt={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 현재시세 ${comma(obj.currentCoin.price)}원)`}
                          />
                        </a>
                      )
                    })}
                </div>
              </>
            ) : null}
          </div>
        )
      })}
    </FloxBox>
  )
}

const Box = styled.div`
  position: relative; margin: 0 auto;
  line-height: 1em; padding: 2rem 0;
  max-width: 1400px; min-width: 1400px;
  @media screen and (max-width: 680px) { max-width: auto; min-width: auto; padding: 0; padding-bottom: 3rem; }
`

const Item = styled.div`
  position: relative;
  & > header {
    padding: 1rem; position: relative; box-sizing: border-box;
    display: flex; align-items: center;
    & > div.icon { font-size: 3em; line-height: 1.8em; margin-right: 1rem; }
    & > div.name { font-weight: 900; font-size: 1.5em; line-height: 1.8em; }
    & > div.fav {
      position: absolute; right: 0; top: 0;
      padding: 2rem 1rem;
      & > a {
        display: inline-block;
        padding: 0.5rem 1rem; border-radius: 0.35rem;
        background: yellow; color: black; font-weight: 700;
      }
    }
  }
  & > section {
    position: relative; padding-bottom: 1rem;
    &> .header {
      padding: 1.5rem;
      font-weight: 900;
      & small { color: #929394 }
    }
    &> .items {
      position: relative; box-sizing: border-box;
      &.rank { display: flex; flex-wrap: wrap; }
      small { color: #555; }
      & > .item {
        padding: 0.25rem; white-space: nowrap; 
        font-size: 0.8em; line-height: 1em; box-sizing: border-box;
        position: relative;
        display: flex; justify-content: space-between; align-items: center;
        @media screen and (max-width: 680px) { font-size: 0.7em; }
        &.fixed {
          flex-wrap: wrap;
          & > div {
            min-width: 100px; max-width: 100px; box-sizing: border-box;
            @media screen and (max-width: 680px) { min-width: 52px; max-width: min-content; min-width: min-content; }
          }
        }
        background: #0b0c11; border-radius: 0.35rem;
        margin-bottom: 0.5rem;
        
        & > .memo {
          position: absolute; right: 0; top: -0.75rem;
          border-radius: 0.35rem;
          font-size: 0.8em; color: black; background: white;
          line-height: 1rem; padding: 0.25rem 0.75rem;
        }
        & > div {
          padding: 0.5rem 0.25rem;
          flex: 1 1 100%; min-width: max-content; max-width: max-content;
          & > div { padding: 0.25rem; }
        }
      }
      & > .rank {
        box-sizing: border-box; transition: all 0.3s;
        background: #0b0c11; border-radius: 0.35rem;
        &:hover { background: #1e202b; }
        min-width: 48%; max-width: 48%; margin: 0.5rem 1%;
        @media screen and (max-width: 680px) { min-width: 100%; max-width: 100%; margin: 0.5rem 0; }
        & > .item {
          padding: 0.25rem; white-space: nowrap; 
          font-size: 0.8em; line-height: 1em; box-sizing: border-box;
          position: relative; display: flex; align-items: center;
          
          &.fixed {
            flex-wrap: wrap;
            & > div {
              max-width: min-content; min-width: 52px; box-sizing: border-box;
              @media screen and (max-width: 680px) { max-width: min-content; min-width: min-content; }
            }
          }
          & > .memo {
            position: absolute; right: 0; top: -0.75rem;
            border-radius: 0.35rem;
            font-size: 0.8em; color: black; background: white;
            line-height: 1rem; padding: 0.25rem 0.75rem;
          }
          & > div {
            padding: 0.5rem 0.25rem;
            flex: 1 1 100%;
            & > div { padding: 0.25rem; }
          }
        }
      & > div.icons {
        padding: 0.5rem; 
        display: flex; flex-wrap: wrap; justify-content: flext-start;
        & > a {
          display: flex; margin: 0.25rem; align-items: center; justify-content: center;
          width: 32px; height: 32px; transition: all 0.3s;
          max-width: 32px; max-height: 32px;
          min-width: 32px; min-height: 32px;
          border-radius: 50%; background: white;
          &.live { border: 3px solid green; }
          &.dead { opacity: 0.8; border: 3px solid red; }
          &.not { opacity: 0.2; border: 3px solid white; }
          & > img {
            width: 20px; height: 20px; border-radius: 50%;
          }
        }
      }
      }
    }
  }
  &:hover div.icons a {
    &.off { opacity: 0.2 !important; }
  }
`

Item.Group = styled.div`
  position: relative; padding: 0.75rem;
`

// 골드크로스, 데드크로스 코인별 구성으로 체크해주기
class Players extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()
    this.state = {
      loading: true,
      me: {},
      players: [],
      player: {},
      aside: false,
      keywords: '',
    }
    this.timer = null
    this.initialize = this.initialize.bind(this)
    this.loadMe = this.loadMe.bind(this)
    this.loadPlayers = this.loadPlayers.bind(this)
    this.doCollectPlayer = this.doCollectPlayer.bind(this)
    this.run = this.run.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {
    const that = this
    await that.loadMe()
    await that.run()
    console.log(`이거 왜 두번 실행하는 것 같지?`)
    if (!this.timer) { this.timer = window.setInterval(that.run, 5000 * 10) }
  }

  async loadMe() {
    const me = {}
    me.id = window.localStorage.getItem('id')
    me.code = window.localStorage.getItem('code')
    if (!me.id || !me.code) { return {} }
    return await fetch(`https://api.coinrbi.com/getTelegram/${_.get(me, 'id')}/${_.get(me, 'code')}`, { signal: this.abortController.signal })
      .then((response) => response.json().catch(e => { return {} }))
      .then(async ({ friend }) => {
        await new Promise(r => this.setState({ me: friend || {}}, r))
        return me
      })
      .catch(e => {
        console.log(e.message)
        return {}
      })
  }

  async run() {
    const that = this
    let players = await that.loadPlayers().catch(e => [])
    if (_.get(this, 'props.mode') === 'me') {
      const ownPlayers = _.get(this, 'state.me.players') || []
      players = players.filter(player => ownPlayers.includes(player.name))
    }
    ReactGA.event({ category: 'Players', action: `Load Players`, label: `Load Players` })
    return await new Promise(r => this.setState({ players, loading: false }, r))
  }

  // 줍줍, 놓아주기
  async doCollectPlayer(user) {
    const me = {}
    me.id = _.get(this, 'state.me.id')
    me.code = _.get(this, 'state.me.code')
    if (!me.id || !me.code) { return {} }

    const headers = new Headers()
    headers.set('Content-Type', 'application/json')

    const prev = _.get(this, 'state.me.players') || []

    const body = {
      players: prev.includes(user)
        ? prev.filter(player => player !== user)
        : [...prev, user]
    }

    return await fetch(`https://api.coinrbi.com/savedPlayers/${_.get(me, 'id')}/${_.get(me, 'code')}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body)
      })
      .then(res => res.json())
      .then(async (data) => {
        if (data.error) { return alert(data.message || `에러가 발생했습니다.`) }
        await this.loadMe()
      })
      .catch(async (e) => {
        alert(e.message || `에러가 발생했습니다.`)
        await this.loadMe()
      })
  }

  async loadPlayers() {
    return await fetch(`https://api.coinrbi.com/reports/realtime_players.json`, { signal: this.abortController.signal })
      .then((response) => response.json().catch(e => []))
      .catch(e => {
        console.log(e.message)
        return []
      })
  }

  render() {
    const that = this
    if (that.state.loading) { return null }

    const search = window.location.search ? qs.parse(window.location.search) : {}
    const players = [...(that.state.players || [])].reverse()
    const groups = [...new Set(players.reduce((objs, obj) => [...objs, obj.group], []))].sort((a, b) => a > b ? 1 : -1)

    const me = this.state.me || {}

    const user = search.user || 'all'
    if (user !== 'all') { ReactGA.event({ category: 'View', action: `Animal Race - ${user.toUpperCase}`, label: `move to Animal Race` }) }

    const rankUsers = [...players]
      .filter((player) => {
        if (!_.get(that, 'state.keywords')) { return true }
        // 플레이어가 현재 보유한 코인들에 대한 리스트에서 코인 리스트를 가져온다.
        return [...player.activatedDetails, ...player.deactivatedDetails].filter(detail => {
          return (_.get(that, 'state.keywords') || ``).split(',')
            .filter(keyword => keyword && keyword.trim())
            .map(keyword => {
              return detail.currentCoin.korean_name.indexOf(keyword) >= 0
                || detail.coinName.indexOf(keyword) >= 0
            }).includes(true)
        }).length > 0
      })
      .map(player => {
        // 방어률 계산
        let totalDefCount = player.totalWinCount + player.totalDrawCount
        player.defRate = (totalDefCount || player.totalLoseCount)
          ? (totalDefCount ? Math.round((totalDefCount/(totalDefCount + player.totalLoseCount)) * 100) : 0)
          : 0

        // 평균수익률
        const profitOrders = player.deactivatedDetails.filter(order => ['win', 'draw'].includes(order.result)) || []
        player.averProfitProfitRate = profitOrders.length ? Math.round((profitOrders.reduce((s, o) => s + Math.round(((o.exitPrice - o.enterancePrice)/o.enterancePrice)*100*100)/100, 0)/profitOrders.length)*100)/100 : 0
        player.sumProfitProfitRate = Math.round((profitOrders.reduce((s, o) => s + Math.round(((o.exitPrice - o.enterancePrice)/o.enterancePrice)*100*100)/100, 0)))

        const loseOrders = player.deactivatedDetails.filter(order => ['lose'].includes(order.result)) || []
        player.averLoseProfitRate = loseOrders.length ? Math.round((loseOrders.reduce((s, o) => s + Math.round(((o.exitPrice - o.enterancePrice)/o.enterancePrice)*100*100)/100, 0)/loseOrders.length)*100)/100 : 0
        player.sumLoseProfitRate = Math.round((loseOrders.reduce((s, o) => s + Math.round(((o.exitPrice - o.enterancePrice)/o.enterancePrice)*100*100)/100, 0)))

        player.totalSum = player.sumProfitProfitRate + player.sumLoseProfitRate

        const score = player.totalWinCount * 2 + player.totalDrawCount * 0.01 + player.totalLoseCount * -1 + player.totalSum * 0.25 + player.sumProfitProfitRate * 0.1 + player.sumLoseProfitRate * 0.1
        return { ...player, score }
      })
      .sort((a, b) => {
        return a.score < b.score ? 1 : -1
      })

    const totalPlayer = rankUsers
      .reduce((rs, r) => {
        rs.activatedDetails = [...(rs.activatedDetails || []), ...r.activatedDetails]
        rs.deactivatedDetails = [...(rs.deactivatedDetails || []), ...r.deactivatedDetails]
        return rs
      }, {})

    return (
      <Box>
        {user === 'all' ? (
          <Helmet>
            <title>애니멀 트레이딩 포트폴리오 - 암호화폐 실시간 고래·타점 코알(코인알비아이)</title>
          </Helmet>
        ) : null}
        {user !== 'all' ? (
          <Helmet>
            <title>애니멀 트레이딩 {user.toUpperCase()} 포트폴리오 - 암호화폐 실시간 고래·타점 코알(코인알비아이)</title>
          </Helmet>
        ) : null}
          
        <Grid>
          <Grid.Aside className={this.state.aside ? 'active' : ''}>

            <Nav.Header>
              <div className="name">
                <div>
                  애니멀 목록<br/>
                </div>
              </div>
              <div className="icon" onClick={e => this.setState({ aside: !this.state.aside })}>
                <i className={!this.state.aside ? "ri-arrow-up-s-line" : "ri-close-line"}></i>
              </div>
            </Nav.Header>

            <Nav>
              <a
                href={`/${that.props.mode === 'me' ? 'me' : 'players'}`}
                className={user === 'all' ? 'active' : ''}
                style={{ maxWidth: '100%' }}
              >
                <span className="icon">🎪</span>
                <span className="text">ALL</span>
              </a>
            </Nav>

            {groups.map((group, groupIdx) => {
              return (
                <Nav key={`Group_${groupIdx}`}>
                  {rankUsers
                    .filter(player => player.group === group)
                    .map((player, playerIdx) => {
                    const completedActivateds = [...(player.activatedDetails || [])].filter(o => o.contract === 'completed').reverse()
                    
                    return (
                      <a
                        href={`/${that.props.mode === 'me' ? 'me' : 'players'}?user=${player.name}`}
                        key={playerIdx}
                        className={user === player.name ? `active${player.totalSum <= 0 ? ` dead` : ''}` : `${player.totalSum <= 0 ? `dead` : ''}`}
                      >
                        <div className="memo">
                          <small style={{ marginRight: '0.25rem' }}>{player.totalSum > 0 ? `🟢` : `🔴`}</small>
                          <strong style={{ color: player.totalSum > 0 ? 'green' : 'red' }}>{`${player.totalSum}%`}</strong>
                        </div>
                        <span className="icon">{player.icon}</span>
                        <span className="text">
                        <span>{`${player.name}`.toUpperCase()}</span>
                        {completedActivateds.length ? (
                          <small style={{ marginLeft: '0.25rem' }}>{comma(completedActivateds.length)}</small>
                        ) : null}
                      </span>
                    </a>)
                  })}
                </Nav>
              )
            })}

          </Grid.Aside>
          <Grid.Body>
            {user === 'all' ? (
              <>
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  autoHeight={true}
                >
                  <SwiperSlide>
                    <Search>
                      <input
                        type="text"
                        defaultValue={that.state.keywords || ''}
                        placeholder={`이더,XRP,제로 등 쉼표로 가볍게 앞자로 검색 가능`}
                        onKeyPress={e => {
                          if (e.key === 'Enter') { that.setState({ keywords: e.target.value }) }
                        }}
                      />
                    </Search>
                    <Item.Group>
                      <Item>
                        <section>
                          <div className="items rank">
                            {rankUsers
                              .map((player, playerIdx) => {
                                return (
                                  <div className="rank" key={`rank_${playerIdx}`}>
                                    <a href={`/${that.props.mode === 'me' ? 'me' : 'players'}?user=${player.name}`} className="item fixed">
                                      <div>
                                        <div style={{ fontSize: '1.3em' }}>{player.icon}</div>
                                      </div>
                                      <div>
                                        <div><strong>{`${player.name}`.toUpperCase()}</strong></div>
                                      </div>
                                      <div>
                                        <div><small>누적수익</small></div>
                                        <div>
                                          <small>{player.totalSum > 0 ? `🟢` : `🔴`}</small>
                                          <strong style={{ color: player.totalSum > 0 ? 'green' : 'red' }}>{`${player.totalSum}%`}</strong>
                                        </div>
                                      </div>
                                      <div>
                                        <div><small>대기·취소</small></div>
                                        <div>
                                          <strong>{player.activatedOrders.filter(o => o.contract === 'wait').length}</strong>
                                          <small>·</small>
                                          <strong style={{ color: '#333' }}>{`${player.totalCancelCount}`}</strong>
                                        </div>
                                      </div>
                                      <div>
                                        <div><small>체결·마감</small></div>
                                        <div>
                                          <strong>{player.activatedOrders.filter(o => o.contract === 'completed').length}</strong>
                                          <small>·</small>
                                          <strong style={{ color: '#333' }}>{player.deactivatedOrders.filter(o => o.contract === 'completed').length}</strong>
                                        </div>
                                      </div>
                                      <div>
                                        <div><small>승·무·패</small></div>
                                        <div>
                                          <strong style={{ color: 'yellow' }}>{`${player.totalWinCount}`}</strong>
                                          <small> / </small>
                                          <strong style={{ color: 'white' }}>{`${player.totalDrawCount}`}</strong>
                                          <small> / </small>
                                          <strong style={{ color: 'red' }}>{`${player.totalLoseCount}`}</strong>
                                        </div>
                                      </div>
                                      <div>
                                        <div><small>승률</small></div>
                                        <div><strong>{`${player.dealRate}%`}</strong></div>
                                      </div>
                                      <div>
                                        <div><small>방어</small></div>
                                        <div><strong>{`${player.defRate}%`}</strong></div>
                                      </div>
                                      <div>
                                        <div><small>평균수익</small></div>
                                        <div><strong>{`${player.averProfitProfitRate}%`}</strong></div>
                                      </div>
                                      <div>
                                        <div><small>평균손실</small></div>
                                        <div><strong>{`${player.averLoseProfitRate}%`}</strong></div>
                                      </div>
                                      <div>
                                        <div><small>수익합산</small></div>
                                        <div><strong>{`${player.sumProfitProfitRate}%`}</strong></div>
                                      </div>
                                      <div>
                                        <div><small>손실합산</small></div>
                                        <div><strong>{`${player.sumLoseProfitRate}%`}</strong></div>
                                      </div>
                                    </a>
                                    <div className="icons">
                                      {player.activatedDetails
                                        .filter(order => order.contract === 'completed')
                                        .filter(order => {
                                          const ownCoins = _.get(that, 'state.me.coins') || []
                                          if (that.props.mode === 'me' && _.get(that, 'state.me.coins.length')) {
                                            return ownCoins.includes(order.coinName) ? true : false
                                          }
                                          return true
                                        })
                                        .filter(detail => {
                                          if (!_.get(that, 'state.keywords')) { return true }
                                          return (_.get(that, 'state.keywords') || ``).split(',')
                                            .filter(keyword => keyword && keyword.trim())
                                            .map(keyword => {
                                              return detail.currentCoin.korean_name.indexOf(keyword) >= 0
                                                || detail.coinName.indexOf(keyword) >= 0
                                            }).includes(true)
                                        })
                                        .map((obj, objIdx) => {
                                          return (
                                            <a
                                              key={objIdx}
                                              href={`/UPBIT:${obj.coinName}KRW`}
                                              target="_blank"
                                              onMouseEnter={() => that.setState({ obj })}
                                              onMouseLeave={() => that.setState({ obj: {} })}
                                              className={obj.currentRate >= 0 ? `live${that.state.obj && that.state.obj.coinName !== obj.coinName ? ` off` : ''}` : `dead${that.state.obj && that.state.obj.coinName !== obj.coinName ? ` off` : ''}`}
                                            >
                                              <img
                                                src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`}
                                                title={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 현재시세 ${comma(obj.currentCoin.price)}원)`}
                                                alt={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 현재시세 ${comma(obj.currentCoin.price)}원)`}
                                              />
                                            </a>
                                          )
                                        })
                                      }
                                      {player
                                        .activatedDetails.filter(order => order.contract === 'wait')
                                        .filter(order => {
                                          const ownCoins = _.get(that, 'state.me.coins') || []
                                          if (that.props.mode === 'me' && _.get(that, 'state.me.coins.length')) {
                                            return ownCoins.includes(order.coinName) ? true : false
                                          }
                                          return true
                                        })
                                        .filter(detail => {
                                          if (!_.get(that, 'state.keywords')) { return true }
                                          return (_.get(that, 'state.keywords') || ``).split(',')
                                            .filter(keyword => keyword && keyword.trim())
                                            .map(keyword => {
                                              return detail.currentCoin.korean_name.indexOf(keyword) >= 0
                                                || detail.coinName.indexOf(keyword) >= 0
                                            }).includes(true)
                                        })
                                        .map((obj, objIdx) => {
                                          return (
                                            <a
                                              key={objIdx}
                                              href={`/UPBIT:${obj.coinName}KRW`}
                                              target="_blank"
                                              onMouseEnter={() => that.setState({ obj })}
                                              onMouseLeave={() => that.setState({ obj: {} })}
                                              className={`not${that.state.obj && that.state.obj.coinName !== obj.coinName ? ` off` : ''}`}
                                            >
                                              <img
                                                src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`}
                                                title={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 현재시세 ${comma(obj.currentCoin.price)}원)`}
                                                alt={`${obj.currentCoin.korean_name}, ${obj.coinName} ${obj.currentRate}% (진입가 ${comma(obj.enterancePrice)}원, 현재시세 ${comma(obj.currentCoin.price)}원)`}
                                              />
                                            </a>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                )
                            })}
                          </div>
                        </section>
                      </Item>
                    </Item.Group>
                  </SwiperSlide>
                  <SwiperSlide>
                    {[totalPlayer]
                      .map((player, playerIdx) => {
                        const waitActivateds = _.sortBy([...(player.activatedDetails || [])].filter(o => o.contract === 'wait'), ['enteranceAt']).reverse()
                          .filter(detail => {
                            if (!_.get(that, 'state.keywords')) { return true } 
                            return (_.get(that, 'state.keywords') || ``).split(',')
                              .filter(keyword => keyword && keyword.trim())
                              .map(keyword => {
                                return detail.currentCoin.korean_name.indexOf(keyword) >= 0
                                  || detail.coinName.indexOf(keyword) >= 0
                              }).includes(true)
                          })
                        
                        return (
                          <Item.Group key={`Item_${playerIdx}`}>
                            <Item>
                              <section>
                                <div className="header">
                                  <div style={{ marginBottom: '0.5rem' }}>대기 <small>{comma(waitActivateds.length)} (감시가 {player.tick}틱 저틱진입)</small></div>
                                </div>
                                {waitActivateds.length > 0 ? (
                                  <div className="items">
                                    {waitActivateds.map((obj, objIdx) => {
                                      const enteranceMt = moment(obj.enteranceAt)
                                      return (
                                        <div className="item" key={`activated_${objIdx}`}>
                                          {obj.memo ? <div className="memo">{obj.memo}</div> : null}
                                          <div style={{ maxWidth: 'min-content', padding: '0 0.35rem', boxSizing: 'border-box' }}>
                                            <div style={{ width: '25px', height: '25px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', boxSizing: 'border-box' }}>
                                              <img src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`} style={{ display: 'block', width: '18px', height: '18px', borderRadius: '50%', boxSizing: 'border-box' }} />
                                            </div>
                                          </div>
                                          <div style={{ fontSize: '0.8em' }}>
                                            <div><strong>{obj.coinName}</strong></div>
                                            <div><small>{obj.currentCoin.korean_name}</small></div>
                                          </div>
                                          <div>
                                            <div><strong>{enteranceMt.format('HH:mm')}</strong></div>
                                            <div><small>{enteranceMt.format('MM.DD')}</small></div>
                                          </div>
                                          <div>
                                            <div><small style={{ marginRight: '0.15rem' }}>{obj.user.toUpperCase()}</small></div>
                                            <div><strong style={{ marginRight: '0.15rem' }}>{comma(obj.enterancePrice)}</strong><small>원</small></div>
                                          </div>
                                          <div style={{ flex: '1 1 100%', minWidth: 'auto', maxWidth: 'auto' }}></div>
                                          <div>
                                            <div>
                                              <small>현재</small>
                                              <strong style={{ marginLeft: '0.15rem' }}>
                                                {comma(obj.currentCoin.price)}
                                              </strong>
                                            </div>
                                            <div>
                                              <small>목표</small>
                                              <strong style={{ marginLeft: '0.15rem', color: 'yellow' }}>
                                                {comma(obj.expectPrice)}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                ) : null}
                              </section>
                          </Item>
                        </Item.Group>
                      )
                    })}
                  </SwiperSlide>
                  <SwiperSlide>
                    {[totalPlayer]
                      .map((player, playerIdx) => {
                        const completedActivateds = _.sortBy([...(player.activatedDetails || [])].filter(o => o.contract === 'completed'), ['enteranceAt']).reverse()
                          .filter(detail => {
                            if (!_.get(that, 'state.keywords')) { return true } 
                            return (_.get(that, 'state.keywords') || ``).split(',')
                              .filter(keyword => keyword && keyword.trim())
                              .map(keyword => {
                                return detail.currentCoin.korean_name.indexOf(keyword) >= 0
                                  || detail.coinName.indexOf(keyword) >= 0
                              }).includes(true)
                          })
                        
                        return (
                          <Item.Group key={`Item_${playerIdx}`}>
                            <Item>
                              <section>
                                <div className="header">
                                  <div style={{ marginBottom: '0.5rem' }}>체결 <small>{comma(completedActivateds.length)} (11시간 이후 자동 포지션 종료)</small></div>
                                </div>
                                {completedActivateds.length > 0 ? (
                                  <div className="items">
                                    {completedActivateds.map((obj, objIdx) => {
                                      const enteranceMt = moment(obj.enteranceAt)
                                      return (
                                        <div className="item" key={`activated_${objIdx}`}>
                                          <div style={{ maxWidth: 'min-content', padding: '0 0.35rem', boxSizing: 'border-box' }}>
                                            <div style={{ width: '25px', height: '25px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', boxSizing: 'border-box' }}>
                                              <img src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`} style={{ display: 'block', width: '18px', height: '18px', borderRadius: '50%', boxSizing: 'border-box' }} />
                                            </div>
                                          </div>
                                          <div style={{ fontSize: '0.8em' }}>
                                            <div><strong>{obj.coinName}</strong></div>
                                            <div><small>{obj.currentCoin.korean_name}</small></div>
                                          </div>
                                          <div>
                                            <div><strong>{enteranceMt.format('HH:mm')}</strong></div>
                                            <div><small>{enteranceMt.format('MM.DD')}</small></div>
                                          </div>
                                          <div style={{ flex: '1 1 100%', minWidth: 'auto', maxWidth: 'auto' }}></div>
                                          <div>
                                            <div>
                                              <small>진입</small>
                                              <strong style={{ marginLeft: '0.15rem', color: 'yellow' }}>
                                                {comma(obj.enterancePrice)}
                                              </strong>
                                            </div>
                                            <div>
                                              <small>시세</small>
                                              <strong style={{ marginLeft: '0.15rem' }}>
                                              {comma(obj.currentCoin.price)}
                                              </strong>
                                            </div>
                                          </div>
                                          <div>
                                            <div><small>{obj.user.toUpperCase()}</small></div>
                                            <div><strong style={{ color: obj.currentRate > 0 ? 'yellow' : 'red' }}>{obj.currentRate}%</strong></div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                ) : null}
                              </section>
                          </Item>
                        </Item.Group>
                      )
                    })}
                  </SwiperSlide>
                  <SwiperSlide>
                    {[totalPlayer]
                      .map((player, playerIdx) => {
                        const completedDeactivateds = _.sortBy([...(player.deactivatedDetails || [])].filter(o => o.contract === 'completed'), ['exitAt']).reverse()
                          .filter(detail => {
                            if (!_.get(that, 'state.keywords')) { return true } 
                            return (_.get(that, 'state.keywords') || ``).split(',')
                              .filter(keyword => keyword && keyword.trim())
                              .map(keyword => {
                                return detail.currentCoin.korean_name.indexOf(keyword) >= 0
                                  || detail.coinName.indexOf(keyword) >= 0
                              }).includes(true)
                          })
                        
                        return (
                          <Item.Group key={`Item_${playerIdx}`}>
                            <Item>
                              <section>
                                <div className="header">
                                  마감 <small>{comma(completedDeactivateds.length)} (최대 표현, 매매 취소는 미포함)</small>
                                </div>

                                {completedDeactivateds.length > 0 ? (
                                  <div className="items">
                                    {completedDeactivateds.map((obj, objIdx) => {
                                      const enteranceMt = moment(obj.enteranceAt)
                                      const exitMt = moment(obj.exitAt)
                                      return (
                                        <div className="item" key={`activated_${objIdx}`}>
                                          <div style={{ maxWidth: 'min-content', padding: '0 0.35rem', boxSizing: 'border-box' }}>
                                            <div style={{ width: '25px', height: '25px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', boxSizing: 'border-box' }}>
                                              <img src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`} style={{ display: 'block', width: '18px', height: '18px', borderRadius: '50%', boxSizing: 'border-box' }} />
                                            </div>
                                          </div>
                                          <div style={{ fontSize: '0.8em' }}>
                                            <div><strong>{obj.coinName}</strong></div>
                                            <div><small>{obj.currentCoin.korean_name}</small></div>
                                          </div>
                                          <div>
                                            <div><strong>{enteranceMt.format('HH:mm')}</strong> <small>{enteranceMt.format('MM.DD')}</small></div>
                                            <div><strong>{exitMt.format('HH:mm')}</strong> <small>{exitMt.format('MM.DD')}</small></div>
                                          </div>
                                          <div style={{ flex: '1 1 100%', minWidth: 'auto', maxWidth: 'auto' }}></div>
                                          <div>
                                            <div>
                                              <small>진입</small>
                                              <strong style={{ marginLeft: '0.15rem', color: 'yellow' }}>
                                                {comma(obj.enterancePrice)}
                                              </strong>
                                            </div>
                                            <div>
                                              <small>{`${obj.result}`.toUpperCase()}</small>
                                              <strong style={{ marginLeft: '0.15rem' }}>
                                              {comma(obj.exitPrice)}
                                              </strong>
                                            </div>
                                          </div>
                                          <div>
                                            <div><small>{obj.user.toUpperCase()}</small></div>
                                            <div><strong style={{ color: obj.currentRate > 0 ? 'yellow' : 'red' }}>{(obj.currentRate)}%</strong></div>
                                          </div>
                                        </div>
                                        )
                                      })}
                                  </div>
                                  ) : null}
                              </section>
                          </Item>
                        </Item.Group>
                      )
                    })}
                  </SwiperSlide>
                </Swiper>
              </>
            ) : null}
            {rankUsers
              .filter(player => {
                return user !== 'all' && player.name === user
              })
              .map((player, playerIdx) => {
                
                const waitActivateds = _.sortBy([...(player.activatedDetails || [])].filter(o => o.contract === 'wait'), ['enteranceAt']).reverse()
                const completedActivateds = _.sortBy([...(player.activatedDetails || [])].filter(o => o.contract === 'completed'), ['enteranceAt']).reverse()
                const completedDeactivateds = _.sortBy([...(player.deactivatedDetails || [])].filter(o => o.contract === 'completed'), ['exitAt']).reverse().slice(0, 30)
                const cancelDeactivateds = _.sortBy([...(player.deactivatedDetails || [])].filter(o => o.contract === 'cancel'), ['exitAt']).reverse().slice(0, 30)
                
                return (
                  <Item.Group key={`Item_${playerIdx}`}>
                    <Item>
                      <header>
                        <div className="fav">
                          <a
                            href="#collectedPlayer"
                            onClick={e => {
                              if (!me.id || !me.code) { return alert(`코알줍줍일기 인증이 필요합니다.`) }
                              return [e.stopPropagation(), e.preventDefault(), this.doCollectPlayer(user)]
                            }}
                          >
                            {(_.get(me, 'players') || []).includes(user) ? `풀어주기` : `줍줍하기`}
                          </a>
                        </div>
                        <div className="icon">{player.icon}</div>
                        <div className="name">
                          <div>
                            {`${player.name}`.toUpperCase()}
                          </div>
                          {<div>
                            <small>{player.totalWinCount}승 {player.totalDrawCount}무 {player.totalLoseCount}패</small>
                            <span style={{ fontWeight: '100', marginLeft: '0.5rem' }}>
                              <small>{player.totalSum > 0 ? `🟢` : `🔴`}</small>
                              <strong style={{ color: player.totalSum > 0 ? 'green' : 'red' }}>{`${player.totalSum}%`}</strong>
                            </span>
                          </div>}
                        </div>
                      </header>

                      <Flow
                        completedActivateds={completedActivateds}
                        completedDeactivateds={completedDeactivateds}
                      />

                      {!waitActivateds.length && !completedActivateds.length && !completedDeactivateds.length ? (
                        <section>
                          <div className="header">
                            해당 애니멀 트레이더는 매수를 쉬고 있어요.
                          </div>
                          <div className="items">
                            <div className="item">
                              <div>
                                <div><strong>종목 미선정</strong></div>
                                <div><small>매수를 쉬고 있습니다.</small></div>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : null}

                      {waitActivateds.length > 0 ? (
                        <section>
                          <div className="header">
                            <div style={{ marginBottom: '0.5rem' }}>대기 <small>{comma(waitActivateds.length)} (감시가 {player.tick}틱 저틱진입)</small></div>
                            <small style={{ fontWeight: '300' }}>현재 11월 25일, 단가가 매우 높은 코인의 경우(10만원 이상)에는 저틱설정의 최대 5배까지 저틱이 늘어나며(예, {player.tick}틱에서 최대 {player.tick * 5}틱 이하의 예상 진입가 위치 설정), 예상진입가 설정 또한 내려갑니다.</small>
                          </div>
                          <div className="items">
                            {waitActivateds.map((obj, objIdx) => {
                              const enteranceMt = moment(obj.enteranceAt)
                              return (
                                <div className="item" key={`activated_${objIdx}`}>
                                  {obj.memo ? <div className="memo">{obj.memo}</div> : null}
                                  <div style={{ maxWidth: 'min-content', padding: '0 0.15rem', boxSizing: 'border-box' }}>
                                    <div style={{ width: '25px', height: '25px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', boxSizing: 'border-box' }}>
                                      <img src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`} style={{ display: 'block', width: '18px', height: '18px', borderRadius: '50%', boxSizing: 'border-box' }} />
                                    </div>
                                  </div>
                                  <div style={{ fontSize: '0.8em' }}>
                                    <div><strong>{obj.coinName}</strong></div>
                                    <div><small>{obj.currentCoin.korean_name}</small></div>
                                  </div>
                                  <div>
                                    <div><strong>{enteranceMt.format('HH:mm')}</strong></div>
                                    <div><small>{enteranceMt.format('MM.DD')}</small></div>
                                  </div>
                                  <div>
                                    <div><small>감시</small> <strong style={{ marginRight: '0.15rem' }}>{comma(obj.enterancePrice)}</strong></div>
                                    <div><small>목표</small> <strong style={{ marginRight: '0.15rem', color: 'yellow' }}>{comma(obj.expectPrice)}</strong></div>
                                  </div>
                                  <div>
                                    <div><small style={{ marginRight: '0.15rem' }}>현재시세</small><small></small></div>
                                    <div><strong style={{ marginRight: '0.15rem' }}>{comma(obj.currentCoin.price)}</strong><small>원</small></div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </section>
                      ) : null}

                      {completedActivateds.length > 0 ? (
                        <section>
                          <div className="header">
                            <div style={{ marginBottom: '0.5rem' }}>체결 <small>{comma(completedActivateds.length)} (11시간 이후 자동 포지션 종료)</small></div>
                            <small style={{ fontWeight: '300' }}>실매매시 애니멀의 체결 단가의 최소 1~10틱 아래는 매수대기를 거는 저틱매매가 낫습니다. 주요 시봉 중 골든크로스 기준으로 진입을 시도해보기 때문에 해당 지점에서 변동성이 심화될 수 있기에 손실을 최소화해야하는 과정에서는 저틱매수가 낫습니다. 그리고 주봉도 꼭 체크하시어, 현재 해당 종목이 어떤 위치에 있고, 얼만큼 올라왔는지를 꼭 체크해보세요. 너무 높은 주봉 위치의 경우에는 관망 포지션이 낫습니다. 실매매 참고시 진입가 더아래의 0T로 써있는 숫자범위까지의 변동성을 의미합니다.</small>
                          </div>
                          <div className="items">
                            {completedActivateds.map((obj, objIdx) => {
                              const enteranceMt = moment(obj.enteranceAt)
                              return (
                                <div className="item" key={`activated_${objIdx}`}>
                                  <div style={{ maxWidth: 'min-content', padding: '0 0.35rem', boxSizing: 'border-box' }}>
                                    <div style={{ width: '25px', height: '25px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', boxSizing: 'border-box' }}>
                                      <img src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`} style={{ display: 'block', width: '18px', height: '18px', borderRadius: '50%', boxSizing: 'border-box' }} />
                                    </div>
                                  </div>
                                  <div style={{ fontSize: '0.8em' }}>
                                    <div><strong>{obj.coinName}</strong></div>
                                    <div><small>{obj.currentCoin.korean_name}</small></div>
                                  </div>
                                  <div>
                                    <div><strong>{enteranceMt.format('HH:mm')}</strong></div>
                                    <div><small>{enteranceMt.format('MM.DD')}</small></div>
                                  </div>
                                  <div>
                                    <div><small>진입</small> <strong style={{ marginRight: '0.15rem' }}>{comma(obj.enterancePrice)}</strong></div>
                                    <div><small>시세</small> <strong style={{ marginRight: '0.15rem', color: 'yellow' }}>{comma(obj.currentCoin.price)}</strong></div>
                                  </div>
                                  <div>
                                    <div><small>현재</small></div>
                                    <div><strong style={{ color: obj.currentRate > 0 ? 'yellow' : 'red' }}>{obj.currentRate}%</strong></div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </section>
                      ) : null}

                      {completedDeactivateds.length > 0 ? (
                        <section>
                          <div className="header">
                            마감 <small>{comma(completedDeactivateds.length)} (30개까지 표현)</small>
                          </div>

                          <div className="items">
                            {completedDeactivateds.map((obj, objIdx) => {
                              const enteranceMt = moment(obj.enteranceAt)
                              const exitMt = moment(obj.exitAt)
                              return (
                                <div className="item" key={`activated_${objIdx}`}>
                                  <div style={{ maxWidth: 'min-content', padding: '0 0.35rem', boxSizing: 'border-box' }}>
                                    <div style={{ width: '25px', height: '25px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', boxSizing: 'border-box' }}>
                                      <img src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`} style={{ display: 'block', width: '18px', height: '18px', borderRadius: '50%', boxSizing: 'border-box' }} />
                                    </div>
                                  </div>
                                  <div style={{ fontSize: '0.8em' }}>
                                    <div><strong>{obj.coinName}</strong></div>
                                    <div><small>{obj.currentCoin.korean_name}</small></div>
                                  </div>
                                  <div>
                                    <div><strong>{enteranceMt.format('HH:mm')}</strong> <small>{enteranceMt.format('MM.DD')}</small></div>
                                    <div><strong>{exitMt.format('HH:mm')}</strong> <small>{exitMt.format('MM.DD')}</small></div>
                                  </div>
                                  <div>
                                    <div><small>진입</small> <strong style={{ marginRight: '0.15rem' }}>{comma(obj.enterancePrice)}</strong></div>
                                    <div><small>마감</small> <strong style={{ marginRight: '0.15rem' }}>{comma(obj.exitPrice)}</strong></div>
                                  </div>
                                  <div>
                                    <div><small>{`${obj.result}`.toUpperCase()}</small></div>
                                    <div><strong style={{ color: obj.currentRate > 0 ? 'yellow' : 'red' }}>{(obj.currentRate)}%</strong></div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </section>
                      ) : null}

                      {cancelDeactivateds.length > 0 ? (
                        <section>
                          <div className="header">
                            취소 <small>{comma(cancelDeactivateds.length)} (최대 30개까지 표현, 매매 취소는 미포함)</small>
                          </div>
                          <div className="items">
                            {cancelDeactivateds.map((obj, objIdx) => {
                              const enteranceMt = moment(obj.enteranceAt)
                              const exitMt = moment(obj.exitAt)
                              return (
                                <div className="item" key={`activated_${objIdx}`}>
                                  {obj.memo ? <div className="memo">{obj.memo}</div> : null}
                                  <div style={{ maxWidth: 'min-content', padding: '0 0.35rem', boxSizing: 'border-box' }}>
                                    <div style={{ width: '25px', height: '25px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', boxSizing: 'border-box' }}>
                                      <img src={`https://api.coinrbi.com/symbols/${obj.coinName}.png`} style={{ display: 'block', width: '18px', height: '18px', borderRadius: '50%', boxSizing: 'border-box' }} />
                                    </div>
                                  </div>
                                  <div style={{ fontSize: '0.8em' }}>
                                    <div><strong>{obj.coinName}</strong></div>
                                    <div><small>{obj.currentCoin.korean_name}</small></div>
                                  </div>
                                  <div>
                                    <div><strong>{enteranceMt.format('HH:mm')}</strong> <small>{enteranceMt.format('MM.DD')}</small></div>
                                    <div><strong>{exitMt.format('HH:mm')}</strong> <small>{exitMt.format('MM.DD')}</small></div>
                                  </div>
                                  <div>
                                    <div><small>목표</small> <strong style={{ marginRight: '0.15rem' }}>{comma(obj.expectPrice)}</strong></div>
                                    <div><small>시세</small> <strong style={{ marginRight: '0.15rem', color: 'yellow' }}>{comma(obj.marketPrice)}</strong></div>
                                  </div>
                                  <div>
                                    <div><small>당시기준</small></div>
                                    <div><strong style={{ color: 'red' }}>{comma(obj.enterancePrice)}</strong></div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </section>
                      ) : null}
                    </Item>
                  </Item.Group>
                )
              })}

          </Grid.Body>
        </Grid>
          
      </Box>
    )
  }
  
  componentWillUnmount() {
    this.abortController.abort()
    if (this.timer) { window.clearInterval(this.timer) }
  }
}

Players.propTypes = {
  handleCoin: PropTypes.func
}

Players.defaultProps = {
  handleCoin: () => {}
}

export default Players